import React from "react";
import { Layout } from "../components";
import { SvgArrow } from "../components";

const About = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "O nas",
        href: pathname,
        lang: "pl",
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/about-us/",
      }}
    >
      <div className="agency agency_container">
        <section className="agency_header">
          <h1>O nas</h1>
          <p>Twój zewnętrzny dział marketingu</p>
        </section>
        <section className="agency_description">
          <p>
            Jesteśmy nastawieni na realizowanie celów naszych Klientów, dlatego
            stawiamy na jasną komunikację, rekomendujemy rozwiązania oraz
            egzekwujemy realizację założeń. Podejmujemy współpracę w oparciu o
            zweryfikowane zaufanie, jako specjaliści na których możesz polegać.
            Jesteśmy Twoim zewnętrznym działem marketingu.Agencję tworzą spółki
            z kluczowych obszarów marketingu. Dzięki wspólnej strukturze
            właścicielskiej (ADream jest współwłaścicielem wszystkich spółek)
            rozwijamy się w sposób systematyczny podnosząc nawzajem swoje
            kompetencje (jesteśmy razem nie tylko w KRS, ale również w jednym
            biurze w centrum Krakowa).
          </p>
        </section>
        <section className="agency_grid">
          <a
            className="agency_grid__item"
            href="https://zensite.pl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <div className="agency_grid__item-logo">
                <img
                  src={require("../assets/img/agency/zensite.svg")}
                  alt="Zensite"
                />
              </div>
              <h3 className="agency_grid__item-name">Zensite</h3>
              <p className="agency_grid__item-spec">
                www / landingpage / wordpress
              </p>
              <p className="agency_grid__item-description">
                Agencja efektywnych i efektownych stron internetowych.
                Użyteczność, nowoczesna grafika i realizacja celów to nasze
                zasady przy tworzeniu stron. Projektujemy i wdrażamy zarówno
                strony typu landing page, strony firm lokalnych, jak i również
                serwisy korporacyjne czy portale internetowe.
              </p>
            </div>
            <div className="agency_grid__item-button">
              <SvgArrow />
            </div>
          </a>
          <a
            className="agency_grid__item"
            href="https://studioleon.pl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <div className="agency_grid__item-logo">
                <img
                  src={require("../assets/img/agency/studio_leon.svg")}
                  alt="Zensite"
                />
              </div>
              <h3 className="agency_grid__item-name">Studio Leon</h3>
              <p className="agency_grid__item-spec">studio graficzne</p>
              <p className="agency_grid__item-description">
                Agencja brandingowa specjalizująca się w przygotowywaniu
                materiałów do druku. Projektujemy identyfikacje wizualne,
                katalogi, opakowania i etykiety. Oferujemy pełne wsparcie w
                procesie wyboru drukarni i nadzoru produkcji.
              </p>
            </div>
            <div className="agency_grid__item-button">
              <SvgArrow />
            </div>
          </a>
          <a
            className="agency_grid__item"
            href="https://when.pl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <div className="agency_grid__item-logo">
                <img
                  src={require("../assets/img/agency/when.svg")}
                  alt="Zensite"
                />
              </div>
              <h3 className="agency_grid__item-name">When</h3>
              <p className="agency_grid__item-spec">marketing internetowy</p>
              <p className="agency_grid__item-description">
                Agencja zajmująca się szeroko pojętą reklamą w internecie:
                Google Ads (AdWords), Facebook Ads, Social Media, LinkedIn Ads,
                oraz analityką internetową. Posiadająca najwyższe odznaczenie
                przyznane przez Google - status Google Premier Partner oraz
                biorąca udział w największych wydarzeniach Google. W dziale SEM
                pracuje 5 certyfikowanych specjalistów Google Ads.
              </p>
            </div>
            <div className="agency_grid__item-button">
              <SvgArrow />
            </div>
          </a>
          <a
            className="agency_grid__item"
            href="https://flyemotion.pl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <div className="agency_grid__item-logo">
                <img
                  src={require("../assets/img/agency/flyemotion.svg")}
                  alt="Zensite"
                />
              </div>
              <h3 className="agency_grid__item-name">Flyemotion</h3>
              <p className="agency_grid__item-spec">motion</p>
              <p className="agency_grid__item-description">
                Agencja specjalizująca się w produkcji filmowej z dronów.
                Wywołujemy emocje podobne do tych, które towarzyszą oglądaniu
                trailerów hollywoodzkich produkcji – kreujemy wizerunek Twojej
                marki. Nie zajmujemy się wideofilmowaniem.
              </p>
            </div>
            <div className="agency_grid__item-button">
              <SvgArrow />
            </div>
          </a>
          <a
            className="agency_grid__item"
            href="https://forsecure.pl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <div className="agency_grid__item-logo">
                <img
                  src={require("../assets/img/agency/forsecure.svg")}
                  alt="Zensite"
                />
              </div>
              <h3 className="agency_grid__item-name">ForSecure</h3>
              <p className="agency_grid__item-spec">secure website</p>
              <p className="agency_grid__item-description">
                Zajmujemy się bezpieczeństwem i aktualizacją stron internetowych
                opartych o system Wordpress. Na systemie Wordpress utrzymywane
                jest około 30% stron internetowych na świecie  przez co jest to
                najczęściej hakowany system. Z nami możesz spać spokojnie.
              </p>
            </div>
            <div className="agency_grid__item-button">
              <SvgArrow />
            </div>
          </a>
          <a
            className="agency_grid__item"
            href="http://morestore.pl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <div className="agency_grid__item-logo">
                <img
                  src={require("../assets/img/agency/morestore.svg")}
                  alt="Zensite"
                />
              </div>
              <h3 className="agency_grid__item-name">Morestore</h3>
              <p className="agency_grid__item-spec">e-commerce</p>
              <p className="agency_grid__item-description">
                Agencja specjalizująca się w projektowaniu serwisów do sprzedaży
                online. Projektujemy i wdrażamy sklepy internetowe zarówno w
                oparciu o rozwiązania open source jak i rozwiązania dedykowane.
              </p>
            </div>
            <div className="agency_grid__item-button">
              <SvgArrow />
            </div>
          </a>
        </section>
        <div className="shield-container">
          <p>
            Adream sp. z o.o. uzyskała subwencję finansową "Tarcza Finansowa
            2.0" udzieloną przez PFR S.A.
          </p>
        </div>
      </div>
    </Layout>
  );
};
export default About;
